













import { Vue, Component, Prop } from "vue-property-decorator";

import { RestaurantStatus } from "@/types";

@Component
export default class RestaurantStatusTag extends Vue {
  @Prop() status!: RestaurantStatus;

  get isPreparing(): boolean {
    return this.status === "PREPARE";
  }
  get isWorking(): boolean {
    return this.status === "WORKING";
  }
  get isSuspended(): boolean {
    return this.status === "SUSPEND";
  }
  get isStopped(): boolean {
    return this.status === "STOP";
  }
  get isClosed(): boolean {
    return this.status === "CLOSE";
  }
  get isStoppedApiLink(): boolean {
    return this.status === "STOP_API_LINK";
  }
  get isSettingUpLedgerLinkage(): boolean {
    return this.status === "SETTING_UP_LEDGER_LINKAGE";
  }

  get label(): string {
    if (this.isPreparing) {
      return "準備中";
    } else if (this.isWorking) {
      return "稼働中";
    } else if (this.isSuspended) {
      return "一時停止";
    } else if (this.isSettingUpLedgerLinkage) {
      return "台帳連携設定中";
    } else if (this.isStopped) {
      return "利用停止 (残務処理中)";
    } else if (this.isClosed) {
      return "利用停止 (終了)";
    } else if (this.isStoppedApiLink) {
      return "利用停止 (API連携停止)";
    } else {
      return "";
    }
  }
}
