import {
  SiteSeatType,
  SiteSeatAttributes,
  SiteSeatConnectingType,
  SiteSeatSmokingType,
  SiteSeatChargeType,
  SiteSeatCountUnit,
  SiteSeatWeekdaySetting
} from "@/types";

import { SiteSeat } from "@/types";

export enum SiteSeatTypeEnum {
  "TABLE",
  "COUNTER",
  "TERRACE",
  "ZASHIKI",
  "KOTATSU",
  "SOFA",
  "PRIVATE_ROOM",
  "SEMI_PRIVATE_ROOM",
  "PRIVATE_OTHER",
  "PRIVATE_TABLE",
  "PRIVATE_COUNTER",
  "PRIVATE_TERRACE",
  "PRIVATE_ZASHIKI",
  "PRIVATE_KOTATSU",
  "PRIVATE_SOFA",
  "SEMI_PRIVATE_OTHER",
  "SEMI_PRIVATE_TABLE",
  "SEMI_PRIVATE_COUNTER",
  "SEMI_PRIVATE_TERRACE",
  "SEMI_PRIVATE_ZASHIKI",
  "SEMI_PRIVATE_KOTATSU",
  "SEMI_PRIVATE_SOFA",
  "OTHER"
}

export const ALL_SITE_SEAT_TYPE: SiteSeatType[] = [
  "TABLE",
  "COUNTER",
  "TERRACE",
  "ZASHIKI",
  "KOTATSU",
  "SOFA",
  "PRIVATE_ROOM",
  "SEMI_PRIVATE_ROOM",
  "PRIVATE_OTHER",
  "PRIVATE_TABLE",
  "PRIVATE_COUNTER",
  "PRIVATE_TERRACE",
  "PRIVATE_ZASHIKI",
  "PRIVATE_KOTATSU",
  "PRIVATE_SOFA",
  "SEMI_PRIVATE_OTHER",
  "SEMI_PRIVATE_TABLE",
  "SEMI_PRIVATE_COUNTER",
  "SEMI_PRIVATE_TERRACE",
  "SEMI_PRIVATE_ZASHIKI",
  "SEMI_PRIVATE_KOTATSU",
  "SEMI_PRIVATE_SOFA",
  "OTHER"
];

export function siteSeatTypeToLabel(
  siteSeatType: SiteSeatType,
  unit: SiteSeatCountUnit,
  seatTypeOtherText?: string
): string {
  // SiteSeatTypeLabel的な型で縛っておくべき？
  const mapping: { [x in SiteSeatType]: string } = {
    OTHER: "その他",
    TABLE: "テーブル",
    COUNTER: "カウンター",
    TERRACE: "テラス",
    ZASHIKI: "座敷",
    KOTATSU: "掘りこたつ",
    SOFA: "ソファー",
    PRIVATE_ROOM: "完全個室",
    SEMI_PRIVATE_ROOM: "半個室",
    PRIVATE_OTHER: "完全個室その他",
    PRIVATE_TABLE: "完全個室テーブル",
    PRIVATE_COUNTER: "完全個室カウンター",
    PRIVATE_TERRACE: "完全個室テラス",
    PRIVATE_ZASHIKI: "完全個室座敷",
    PRIVATE_KOTATSU: "完全個室掘りごたつ",
    PRIVATE_SOFA: "完全個室ソファー",
    SEMI_PRIVATE_OTHER: "半個室その他",
    SEMI_PRIVATE_TABLE: "半個室テーブル",
    SEMI_PRIVATE_COUNTER: "半個室カウンター",
    SEMI_PRIVATE_TERRACE: "半個室テラス",
    SEMI_PRIVATE_ZASHIKI: "半個室座敷",
    SEMI_PRIVATE_KOTATSU: "半個室掘りごたつ",
    SEMI_PRIVATE_SOFA: "半個室ソファー"
  };

  let siteSeatTypeLabel = mapping[siteSeatType];
  if (unit === "PEOPLE") {
    siteSeatTypeLabel = `${siteSeatTypeLabel}[人]`;
  }
  if (siteSeatType === "OTHER" && seatTypeOtherText) {
    siteSeatTypeLabel = `${siteSeatTypeLabel}(${seatTypeOtherText})`;
  }
  return siteSeatTypeLabel;
}

function siteSeatAttributesToLabels(
  siteSeatAttributes: SiteSeatAttributes
): string[] {
  const labels: string[] = [];
  if (siteSeatAttributes.kotatsu) labels.push("掘りごたつ");
  if (siteSeatAttributes.sofa) labels.push("ソファー");
  if (siteSeatAttributes.zashiki) labels.push("座敷");
  if (siteSeatAttributes.beerGarden) labels.push("ビアガーデン");
  if (siteSeatAttributes.coupleSeat) labels.push("カップルシート");
  if (siteSeatAttributes.kawadoko) labels.push("川床");
  if (siteSeatAttributes.windowSeat) labels.push("窓際");
  if (siteSeatAttributes.privateTable) labels.push("テーブル");
  return labels;
}

function siteSeatSmokingTypeToLabel(
  siteSeatSmokingType: SiteSeatSmokingType
): string {
  const mapping: { [x in SiteSeatSmokingType]: string } = {
    UNSPECIFIED: "未指定",
    NOT_ALLOWED: "禁煙",
    ALLOWED: "喫煙"
  };
  return mapping[siteSeatSmokingType];
}

function siteSeatConnectingTypeToLabel(
  siteSeatConnectingType: SiteSeatConnectingType
): string {
  const mapping: { [x in SiteSeatConnectingType]: string } = {
    NO: "コネ不可",
    YES: "コネ可"
  };
  return mapping[siteSeatConnectingType];
}

function siteSeatChargeTypeToLabel(
  siteSeatChargeType: SiteSeatChargeType
): string {
  const mapping: { [x in SiteSeatChargeType]: string } = {
    NOT_CHARGED: "チャージなし",
    CHARGED: "チャージあり"
  };
  return mapping[siteSeatChargeType];
}

export function siteSeatPropertiesToLabels(siteSeat: SiteSeat): string[] {
  let labels: string[] = [];
  labels.push(
    siteSeatTypeToLabel(siteSeat.type, siteSeat.unit, siteSeat.typeOtherText)
  );
  labels = labels.concat(siteSeatAttributesToLabels(siteSeat.attributes));

  if (siteSeat.smokingType !== "UNSPECIFIED") {
    labels.push(siteSeatSmokingTypeToLabel(siteSeat.smokingType));
  }
  if (siteSeat.connectingType === "YES") {
    labels.push(siteSeatConnectingTypeToLabel(siteSeat.connectingType));
  }

  labels.push(siteSeatChargeTypeToLabel(siteSeat.chargeType));

  return labels;
}

export function siteSeatCapacityLabel(siteSeat: SiteSeat): string {
  let capacityMin = siteSeat.capacity.min;
  let capacityMax = siteSeat.capacity.max;
  if (siteSeat.isCapacitiesEnabled) {
    if (siteSeat.capacities) {
      capacityMin = Math.min(...siteSeat.capacities);
      capacityMax = Math.max(...siteSeat.capacities);
    } else {
      return "未設定";
    }
  }
  return capacityMin === capacityMax
    ? `${capacityMin}名`
    : `${capacityMin}〜${capacityMax}名`;
}

export function weekdaySettingHasValidTime(
  weekdaySetting?: SiteSeatWeekdaySetting
): boolean {
  if (!weekdaySetting?.serviceTimeRange) {
    return false;
  }
  return !(
    weekdaySetting.serviceTimeRange.begin ===
      weekdaySetting.serviceTimeRange.end &&
    weekdaySetting.serviceTimeRange.begin === "00:00"
  );
}
