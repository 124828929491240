import { TimeZone } from "@/types";

export const ALL_TIME_ZONE: TimeZone[] = [
  "BREAKFAST",
  "LUNCH",
  "TEATIME",
  "DINNER",
  "UNKNOWN"
];

export function timeZoneToLabel(timeZone: TimeZone): string {
  const mapping: { [x in TimeZone]: string } = {
    UNKNOWN: "指定なし",
    BREAKFAST: "朝食",
    LUNCH: "ランチ",
    TEATIME: "ティー",
    DINNER: "ディナー"
  };
  return mapping[timeZone];
}
