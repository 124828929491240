






import { Vue, Component, Prop } from "vue-property-decorator";
import { TimeZone } from "@/types";
import { timeZoneToLabel } from "@/tools/timeZone";

@Component({
  filters: { timeZoneToLabel }
})
export default class TimeZoneTag extends Vue {
  @Prop() timeZone!: TimeZone;

  get className(): string {
    switch (this.timeZone) {
      case "BREAKFAST":
        return "is-breakfast";
      case "LUNCH":
        return "is-lunch";
      case "TEATIME":
        return "is-teatime";
      case "DINNER":
        return "is-dinner";
      case "UNKNOWN":
        return "";
      default:
        return "";
    }
  }
}
