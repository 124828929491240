









import Vue from "vue";
export default Vue.extend({
  props: {
    disabled: { type: Boolean, default: false }
  }
});
