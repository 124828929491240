import {
  isSameDay,
  startOfMonth,
  format,
  addMinutes,
  addDays,
  parseISO,
  eachDayOfInterval,
  endOfMonth,
  isPast,
  isToday
} from "date-fns";

export function isPastDate(date: Date): boolean {
  return isPast(date) && !isToday(date);
}

export function isStartOfMonth(date: Date): boolean {
  return isSameDay(startOfMonth(date), date);
}

export function daysOfMonth(current: Date): Date[] {
  return eachDayOfInterval({
    start: startOfMonth(current),
    end: endOfMonth(current)
  });
}

export function strToDate(dateStr: string): Date {
  const dateStrArray = dateStr.split("-");
  const dateStrObject = {
    year: parseInt(dateStrArray[0]),
    month: parseInt(dateStrArray[1]),
    day: parseInt(dateStrArray[2])
  };
  const { year, month, day } = dateStrObject;
  const date = new Date(year, month - 1, day);
  return date;
}

// Date型の日付とstring型の時間からDate型を作る
// timeはHH:mmで表記される
// レストランバックエンドが返してくる24時以降の時間に対応する
export function dateFromTime24(date: Date, time: string): Date {
  const matched = time.match(/(\d\d):(\d\d)/);
  if (matched) {
    const [, hh, mm] = matched;
    const isTomorrow = parseInt(hh) >= 24;
    const fixedHour = isTomorrow ? `${parseInt(hh) - 24}`.padStart(2, "0") : hh;
    const fiexdDate = isTomorrow ? addDays(date, 1) : date;
    return parseISO(`${format(fiexdDate, "yyyy-MM-dd")} ${fixedHour}:${mm}`);
  } else {
    throw new Error("Invalid time format.");
  }
}

export function timeToTime24(time: string): string {
  const matched = time.match(/(\d\d):(\d\d)/);
  if (matched) {
    const [, hh, mm] = matched;
    if (0 <= parseInt(hh) && parseInt(hh) <= 3) {
      return `${parseInt(hh) + 24}:${mm}`;
    }
  }
  return time;
}

// 23:30 などの時間をstringで渡すと 24:00 など次の時間を返す
export function nextThirtyMinutesLater(time: string): string {
  const d = dateFromTime24(new Date(), time);
  const t = format(addMinutes(d, 30), "HH:mm");
  return timeToTime24(t);
}
