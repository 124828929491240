


























// FIXME: もっと良いpolyfillの注入方法にしたい
import "element-closest";

import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DropdownMenu extends Vue {
  active = false;
  @Prop({ required: true }) label!: string;
  @Prop({ default: () => false }) disabled!: boolean;
  @Prop({ default: () => "dropdown-menu" }) id!: string;

  open() {
    this.active = true;
  }

  close() {
    this.active = false;
  }

  // FIXME: any 消す
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private closeIfOutsideClicked(event: any) {
    if (!event.target.closest(`#${this.id}`)) {
      this.close();
    }
  }

  created() {
    document.addEventListener("click", this.closeIfOutsideClicked);
    document.addEventListener("touchstart", this.closeIfOutsideClicked);
  }

  // template上でslotにリスナーを設定できないので、ここで設定する
  mounted() {
    this.$children.forEach(child => {
      child.$on("click", this.close);
    });
  }

  beforeDestroy() {
    document.removeEventListener("click", this.closeIfOutsideClicked);
    document.removeEventListener("touchstart", this.closeIfOutsideClicked);
  }
}
