












































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Snackbar extends Vue {
  @Prop() messages!: string[];
  @Prop() actions?: [string, () => void][];
  @Prop() duration?: number;
  @Prop({ default: false }) isError?: boolean;
  @Prop({ default: false }) containsHtmlTag?: boolean;
  shown = false;

  show() {
    this.attach();
    this.shown = true;
    if (this.duration) {
      setTimeout(() => {
        this.hide();
      }, this.duration);
    }
  }

  hide() {
    this.shown = false;
  }

  executeAction(action: () => void) {
    action();
    this.hide();
  }

  attach() {
    if (!this.$parent) {
      this.$mount();
      document.body.appendChild(this.$el);
    } else {
      this.$mount();
      this.$parent.$el.appendChild(this.$el);
    }
  }

  remove() {
    if (!this.$parent) {
      document.body.removeChild(this.$el);
      this.$destroy();
    } else {
      this.$parent.$el.removeChild(this.$el);
      this.$destroy();
    }
  }

  afterLeave() {
    this.remove();
  }
}
