












import { Vue, Component, Prop } from "vue-property-decorator";

import { SharedSeatStatus } from "@/types";

@Component
export default class SharedSeatStatusTag extends Vue {
  @Prop() status!: SharedSeatStatus;

  get isSuspened(): boolean {
    return this.status === "SUSPENDED";
  }
  get isActive(): boolean {
    return this.status === "ACTIVE";
  }
  get isHidden(): boolean {
    return this.status === "HIDDEN";
  }

  get label(): string {
    if (this.isSuspened) {
      return "連携停止";
    } else if (this.isActive) {
      return "連携中";
    } else if (this.isHidden) {
      // 用意はしておくがこのラベルを表示することはない
      return "非表示";
    } else {
      return "";
    }
  }
}
