import { PluginObject } from "vue";
import T from "./Snackbar.vue";

type SnackbarOption = {
  messages: string[];
  actions?: [string, () => void][];
  duration?: number;
  isError?: boolean;
  containsHtmlTag?: boolean;
};

declare module "vue/types/vue" {
  interface Vue {
    $snackbar(args: SnackbarOption): void;
  }
  interface VueConstructor {
    snackbar(args: SnackbarOption): void;
  }
}

interface SnackbarInterface {
  show(): void;
  hide(): void;
}

export const Snackbar: PluginObject<{}> = {
  install(Vue) {
    Vue.snackbar = Vue.prototype.$snackbar = function(args: SnackbarOption) {
      const $parent = this instanceof Vue ? this : undefined;
      const $vm: SnackbarInterface = new T({
        parent: $parent,
        propsData: {
          ...args
        }
      });
      $vm.show();
    };
  }
};
