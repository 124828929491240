export function getRestaurantId(): number {
  const restaurantIdField = document.getElementById(
    "bodyRestaurantId"
  ) as HTMLInputElement;
  return parseInt(restaurantIdField.value);
}

function setReservationBadge(count: number) {
  count *= 0; // 未対応予約数をヘッダに表示する機能は、一旦無効化するため強制的に0にする
  const badgeValueElement = document.getElementById("reservationBadgeValue");
  if (!badgeValueElement) {
    return;
  }
  const badgeValue = badgeValueElement as HTMLInputElement;
  badgeValue.value = count.toString();
  const badge = document.getElementById("reservationBadge");
  if (badge) {
    if (count > 9) {
      badge.innerText = "9+";
    } else {
      badge.innerText = count.toString();
    }
    if (count > 0) {
      badge.classList.remove("is-hidden");
    } else {
      badge.classList.add("is-hidden");
    }
  }
}

function checkReservationUpdate(new_reservations: number) {
  const target_element = document.getElementById("newReservationNotifyField");
  if (!target_element) {
    return;
  }
  const number_element = document.getElementById("newReservationNotifyNumber");
  if (!number_element) {
    return;
  }
  if (new_reservations > 0) {
    number_element.innerText = new_reservations.toString();
    target_element.classList.remove("is-hidden");
  }
}

export function updateUncheckReservationsNum() {
  const badgeValueElement = document.getElementById("reservationBadgeValue");
  if (!badgeValueElement) {
    // reservationBadgeValueがない場合は取得しに行かない
    return;
  }
  const last_updated_element = document.getElementById("lastUpdatedAtValue");
  let params = "";
  if (last_updated_element) {
    const last_updated_at = (last_updated_element as HTMLInputElement).value;
    params =
      "?" +
      new URLSearchParams({
        last_updated_at: last_updated_at
      }).toString();
  }
  fetch(
    "/restaurants/" +
      getRestaurantId().toString() +
      "/reservations/uncheck_count" +
      params,
    {
      method: "GET",
      mode: "cors"
    }
  )
    .then(function(response) {
      if (response.ok) {
        response.json().then(function(resJson) {
          setReservationBadge(resJson.unchecked_num);
          checkReservationUpdate(resJson.new_reservations);
        });
      }
    })
    .catch(function(error) {
      console.error(error);
    });
}

export function diffReservationsNum(num: number) {
  const badgeValue = document.getElementById(
    "reservationBadgeValue"
  ) as HTMLInputElement;
  const new_value = Math.max(parseInt(badgeValue.value) + num, 0);
  setReservationBadge(new_value);
}
