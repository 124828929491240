
















































































import { Vue, Component, Prop } from "vue-property-decorator";
import Calendar from "./Calendar/index.vue";
import {
  format,
  addMonths,
  isBefore,
  isAfter,
  startOfToday,
  endOfMonth,
  addYears,
  eachDayOfInterval,
  startOfMonth,
  differenceInDays
} from "date-fns";

export type SelectedDate = { [key: string]: boolean };

@Component({
  components: { Calendar },
  methods: { format, addMonths, isBefore, isAfter }
})
export default class PopupContent extends Vue {
  @Prop() defaultSelectedDays!: Date[];
  @Prop() holidays!: string[];
  @Prop() defaultMonth!: Date;
  @Prop({ default: "自動設定除外日" }) title!: string;
  currentMonth = this.defaultMonth;

  selectedDatesMap: SelectedDate = Object.fromEntries(
    eachDayOfInterval({
      start: startOfMonth(this.currentMonth),
      end: endOfMonth(addYears(this.currentMonth, 1))
    }).map(date => [format(date, "yyyy-MM-dd"), false])
  );

  created() {
    for (const day of this.defaultSelectedDays) {
      this.selectedDatesMap[format(day, "yyyy-MM-dd")] = true;
    }
  }

  get underLimitDate() {
    return startOfToday();
  }

  get upperLimitDate() {
    return endOfMonth(addYears(startOfToday(), 1));
  }

  get chunkedDays() {
    let chunks: Date[][] = [];
    const today = new Date();
    const filteredSelectedDates = this.selectedDates.filter(
      date => format(date, "yyyy-MM-dd") >= format(today, "yyyy-MM-dd")
    );
    for (const day of filteredSelectedDates) {
      const lastChunk = chunks.pop() ?? [];
      if (
        lastChunk.length === 0 ||
        differenceInDays(day, lastChunk[lastChunk.length - 1]) === 1
      ) {
        chunks = [...chunks, [...lastChunk, day]];
      } else {
        chunks = [...chunks, lastChunk, [day]];
      }
    }
    return chunks;
  }

  get chunkedDaysToString(): string[][] {
    return this.chunkedDays.map(days => {
      return days.map(day => {
        return format(day, "yyyy/MM/dd");
      });
    });
  }

  get selectedDates() {
    return Object.entries(this.selectedDatesMap)
      .filter(([, selected]) => selected)
      .map(([date]) => new Date(date))
      .sort((a, b) => (a > b ? 1 : -1));
  }

  close() {
    this.$emit("close");
  }

  commit() {
    this.$emit("commit", this.selectedDates);
    this.close();
  }
}
