import { restaurantClient, GraphQLResponse } from "@/generic/apiClient";
import {
  convertGraphQLError,
  convertAxiosError
} from "@/generic/ErrorConverter";
import {
  CurrentContext,
  GlobalNotice,
  BusinessRules,
  HolidayCalendar
} from "@/types";
import { format } from "date-fns";

export type HolidayCalendarResponse = {
  calendar: HolidayCalendar[];
};

export async function fetchHolidays(begin: Date, end: Date) {
  const {
    data: { data, errors }
  } = await restaurantClient.post<GraphQLResponse<HolidayCalendarResponse>>(
    "/graphql",
    {
      query: `
        query {
            calendar (begin: "${format(begin, "yyyy-MM-dd")}", end: "${format(
        end,
        "yyyy-MM-dd"
      )}", holiday: true) {
              date
              holiday
              dayOfWeek
              salesWeekday
            }
          }
        `
    }
  );
  return !errors && data
    ? Promise.resolve(data.calendar)
    : Promise.reject(errors);
}

export async function getCurrentContextRepository(
  restaurantId: number
): Promise<{
  currentContext: CurrentContext;
  globalNotices: GlobalNotice[];
  businessRules: BusinessRules;
}> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        currentContext: CurrentContext;
        globalNotices: GlobalNotice[];
        businessRules: BusinessRules;
      }>
    >("/graphql", {
      query: `
            query {
              currentContext(restaurantId: ${restaurantId}) {
                operator {
                  name
                  ikyuAdmin
                  powerUser
                  readonly
                  groupId
                }
                restaurant {
                  restaurantId
                  name
                  status
                  hasSiteError
                  isLinkedToLedger
                  isInventoryLinkedToLedger
                  inventoryControllableInLedgerLinkage
                  ledger
                  showTablecheckInfo
                  showTermsAndConditions
                }
                newFeatureFlags {
                  name
                  enabled
                }
              }
              globalNotices {
                title
                description
                isActive
              }
              businessRules {
                editableDates {
                  begin
                  end
                }
                referableDates {
                  begin
                  end
                }
              }
            }
          `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();

    const { currentContext, globalNotices, businessRules } = data;
    return { currentContext, globalNotices, businessRules };
  } catch (e) {
    throw convertAxiosError(e);
  }
}

export async function getUncheckedReservationsCountRepository(
  restaurantId: number
): Promise<{
  uncheckedReservationsCount: number;
}> {
  try {
    const {
      data: { data, errors }
    } = await restaurantClient.post<
      GraphQLResponse<{
        currentContext: {
          restaurant: {
            uncheckedReservationsCount: number;
          };
        };
      }>
    >("/graphql", {
      query: `
            query {
              currentContext(restaurantId: ${restaurantId}) {
                restaurant {
                  uncheckedReservationsCount
                }
              }
            }
          `
    });
    if (errors) throw convertGraphQLError(errors);
    if (!data) throw new Error();

    const {
      currentContext: {
        restaurant: { uncheckedReservationsCount }
      }
    } = data;
    return { uncheckedReservationsCount };
  } catch (e) {
    throw convertAxiosError(e);
  }
}
