import Vue, { PluginObject } from "vue";
import T from "./Toast.vue";

type ToastOption = {
  messages: string[];
  actions?: [string, () => void][];
  duration?: number;
  isError?: boolean;
  isWarning?: boolean;
};

declare module "vue/types/vue" {
  interface Vue {
    $toast(args: ToastOption): ToastInterface;
  }
  interface VueConstructor {
    toast(args: ToastOption): ToastInterface;
  }
}

export interface ToastInterface {
  show(): void;
  hide(): void;
}

export const Toast: PluginObject<{}> = {
  install(Vue) {
    Vue.toast = Vue.prototype.$toast = function(args: ToastOption) {
      const $parent = this instanceof Vue ? this : undefined;
      const $vm: ToastInterface = new T({
        parent: $parent,
        propsData: {
          ...args
        }
      });
      $vm.show();
      return $vm;
    };
  }
};
