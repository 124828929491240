







import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
@Component
export default class ToggleButton extends Vue {
  @Prop({ default: false })
  defaultToggle!: boolean;
  toggle = false;

  mounted() {
    this.toggle = this.defaultToggle;
  }

  @Watch("defaultToggle")
  onChangeDefaultToggle(n: boolean, o: boolean) {
    if (n !== o) this.toggle = n;
  }

  @Emit()
  click() {
    this.toggle = !this.toggle;
    return this.toggle;
  }
}
