/* eslint-disable @typescript-eslint/no-use-before-define */
import { customErrorFactory } from "ts-custom-error";
import Axios, { AxiosError } from "axios";
import { GrapQLError } from "@/generic/apiClient";

export function convertAxiosError<T>(error: AxiosError<T>): Error {
  if (error instanceof GraphQLError) {
    return error;
  }
  if (Axios.isCancel(error)) {
    return new CancelError();
  }

  const noResponse = error.response === undefined;
  if (noResponse) {
    return new TimeoutError();
  }

  const status = error.response?.status;
  switch (status) {
    case 401:
      return new UnauthorizedError();
    case 403:
      return new ForbiddenError();
    case 500:
      return new ServerError();
    default:
      return error;
  }
}

export function convertGraphQLError(
  errors: GrapQLError | GrapQLError[]
): Error {
  return new GraphQLError(Array.isArray(errors) ? errors : [errors]);
}

type RecoveryType = "RELOAD" | "LOGIN";

export type ApplicationErrorParams = {
  message: string;
  recoveryType?: RecoveryType;
  recoveryButtonyMessage?: string;
};

export const ApplicationError = customErrorFactory<ApplicationErrorParams>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function UserDisplayError() {}
);

export const CancelError = customErrorFactory<ApplicationErrorParams>(
  function CancelError() {
    this.message = "要求がキャンセルされました";
  }
);

export const UnauthorizedError = customErrorFactory<ApplicationErrorParams>(
  function UnauthorizedError() {
    this.message = "[401] オペレーター認証が必要です";
    this.recoveryButtonyMessage = "ログイン画面に戻る";
    this.recoveryType = "LOGIN";
  },
  ApplicationError
);

export const ForbiddenError = customErrorFactory<ApplicationErrorParams>(
  function ForbiddenError() {
    this.message = "[403] この画面を参照する権限がありません";
    this.recoveryButtonyMessage = "ログイン画面に戻る";
    this.recoveryType = "LOGIN";
  },
  ApplicationError
);

export const TimeoutError = customErrorFactory<ApplicationErrorParams>(
  function TimeoutError() {
    this.message = "要求がタイムアウトしました";
    this.recoveryButtonyMessage = "再読み込み";
    this.recoveryType = "RELOAD";
  },
  ApplicationError
);

export const ServerError = customErrorFactory<ApplicationErrorParams>(
  function TimeoutError() {
    this.message =
      "[500] サーバーでエラーが発生しました\n最初からやり直してください";
  },
  ApplicationError
);

export const GraphQLError = customErrorFactory<ApplicationErrorParams>(
  function GraphQLError(errors: GrapQLError[]) {
    this.message = `サーバーでエラーが発生しました${"\n"}${errors
      .map(e => e.message)
      .join("\n")}`;
  },
  ApplicationError
);
