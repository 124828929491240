import axios, { AxiosResponse } from "axios";

export interface GrapQLError {
  message: string;
}

export interface GraphQLResponse<T> {
  data: T | undefined;
  errors: GrapQLError[] | undefined;
}

const restaurantClient = axios.create({
  timeout: 30_000,
  baseURL: "/"
});

const restaurantMockClient = axios.create({
  timeout: 16_000,
  baseURL: "http://localhost:5000"
});

function logGraphQL200Errors(response: AxiosResponse<GraphQLResponse<any>>) {
  const {
    data: { errors }
  } = response;
  if (errors) {
    // eslint-disable-next-line no-console
    console.error(errors);
  }
  return response;
}

function logGraphQLErrors(error: any) {
  if (error.response) {
    const {
      data: { errors }
    } = error.response as GraphQLResponse<any>;
    // eslint-disable-next-line no-console
    console.error(errors);
  }
  return Promise.reject(error);
}

restaurantClient.interceptors.response.use(
  logGraphQL200Errors,
  logGraphQLErrors
);

restaurantMockClient.interceptors.response.use(
  logGraphQL200Errors,
  logGraphQLErrors
);

export { restaurantClient, restaurantMockClient };
