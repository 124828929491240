import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=89c01864&scoped=true&"
import script from "./Snackbar.vue?vue&type=script&lang=ts&"
export * from "./Snackbar.vue?vue&type=script&lang=ts&"
import style0 from "./Snackbar.vue?vue&type=style&index=0&module=true&lang=css&"
import style1 from "./Snackbar.vue?vue&type=style&index=1&id=89c01864&lang=postcss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "89c01864",
  null
  
)

export default component.exports