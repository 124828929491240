import { restaurantClient, GraphQLResponse } from "@/generic/apiClient";
import {
  convertGraphQLError,
  convertAxiosError
} from "@/generic/ErrorConverter";
import { SiteSeat } from "@/types";
import { Canceler } from "axios";
import {
  LinkageEventGroupSet,
  LinkageEventGroupStatus,
  SharedSeat
} from "../inventory_calendar/types";
import {
  RequestSitePlanRefresh,
  RequestSiteSeatRefresh,
  Site,
  SitePlan
} from "./types";

export class PlanRepository {
  private cancelers: Canceler[] = [];

  async getSites(restaurantId: number): Promise<Site[]> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            sites: Site[];
          };
        }>
      >("/graphql", {
        query: `
              query{
                restaurant(id: ${restaurantId}) {
                  sites {
                    siteType
                    enabled
                  }
                }
              }
            `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      const {
        restaurant: { sites }
      } = data;
      return sites;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async getSiteSeatRepository(restaurantId: number): Promise<SiteSeat[]> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            siteSeats: SiteSeat[];
          };
        }>
      >("/graphql", {
        query: `
          query {
              restaurant(id: ${restaurantId}) {
                siteSeats {
                  siteSeatId
                  realSeatId
                  displayId
                  displayRealSeatId
                  name
                  siteType
                  capacity {
                    min
                    max
                  }
                  displayServiceTime {
                    serviceTimeRange {
                      begin,
                      end
                    }
                  }
                  capacities
                  isCapacitiesEnabled
                  hasSettings
                  maxInventoryCount
                  type
                  typeOtherText
                  attributes {
                    zashiki
                    kotatsu
                    sofa
                    beerGarden
                    coupleSeat
                    kawadoko
                    windowSeat
                    privateTable
                  }
                  connectingType
                  smokingType
                  chargeType
                  linkageStatus {
                    isError
                    code
                    label
                  }
                  isTimeframeInventory
                  isGroupSeat
                  weekdaySettings {
                    weekday
                    priority
                    rotation
                    closed
                    unavailable
                    serviceTimeRange {
                      begin
                      end
                    }
                  }
                  unit
                  weight
                }
              }
            }
          `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.restaurant.siteSeats;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async getSharedSeatRepository(restaurantId: number): Promise<SharedSeat[]> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            sharedSeats: SharedSeat[];
          };
        }>
      >("/graphql", {
        query: `
          query {
            restaurant(id: ${restaurantId}) {
              sharedSeats {
                sharedSeatId,
                name,
                timezone,
                status,
                shared,
                siteSeats {
                  siteSeatId
                }
              }
            }
          }
        `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.restaurant.sharedSeats;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async getSitePlanRepository(restaurantId: number): Promise<SitePlan[]> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            sitePlans: SitePlan[];
          };
        }>
      >("/graphql", {
        query: `
          query {
              restaurant(id: ${restaurantId}) {
                sitePlans {
                  id,
                  siteType,
                  name,
                  realPlanId,
                  displayRealPlanId,
                  siteStatus,
                  timezone,
                  capacity {
                    min,
                    max
                  },
                  price,
                  visitDate {
                    begin,
                    end
                  },
                  reservationAcceptDate {
                    begin,
                    end
                  },
                  siteDisplayOrder,
                  siteCreatedAt,
                  siteUpdatedAt,
                  associateToAllSeats,
                  seatAssociations {
                    siteType,
                    realPlanId,
                    realSeatId
                  }
                  weekdaySettings {
                    sitePlanId,
                    weekday,
                    priority,
                    visitTime {
                      begin,
                      end
                    },
                    stayTime,
                    reservationDeadline {
                      days,
                      time
                    },
                    defaultSalesSuspend,
                    unavailable
                  }
                  publishStatus
                }
              }
            }
          `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.restaurant.sitePlans;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async requestSitePlanRefresh(restaurantId: number): Promise<boolean> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<GraphQLResponse<RequestSitePlanRefresh>>(
        "/graphql",
        {
          query: `
        mutation {
          requestSitePlanRefresh(restaurantId: ${restaurantId}) {
            success
          }
        }
      `
        }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.requestSitePlanRefresh.success;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async fetchSitePlanRefreshStatus(
    restaurantId: number
  ): Promise<LinkageEventGroupStatus> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            linkageEventGroupSet: LinkageEventGroupSet;
          };
        }>
      >("/graphql", {
        query: `
        query{
          restaurant(id: ${restaurantId}) {
            linkageEventGroupSet {
              sitePlanRefresh {
                status
              }
            }
          }
        }
      `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      const {
        restaurant: {
          linkageEventGroupSet: {
            sitePlanRefresh: { status }
          }
        }
      } = data;
      return status;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async requestSiteSeatRefresh(restaurantId: number): Promise<boolean> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<GraphQLResponse<RequestSiteSeatRefresh>>(
        "/graphql",
        {
          query: `
            mutation {
              requestSiteSeatRefresh(restaurantId: ${restaurantId}) {
                success
              }
            }
          `
        }
      );
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      return data.requestSiteSeatRefresh.success;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  async fetchSiteSeatRefreshStatus(
    restaurantId: number
  ): Promise<LinkageEventGroupStatus> {
    try {
      const {
        data: { data, errors }
      } = await restaurantClient.post<
        GraphQLResponse<{
          restaurant: {
            linkageEventGroupSet: LinkageEventGroupSet;
          };
        }>
      >("/graphql", {
        query: `
        query{
          restaurant(id: ${restaurantId}) {
            linkageEventGroupSet {
              siteSeatRefresh {
                status
              }
            }
          }
        }
      `
      });
      if (errors) throw convertGraphQLError(errors);
      if (!data) throw new Error();
      const {
        restaurant: {
          linkageEventGroupSet: {
            siteSeatRefresh: { status }
          }
        }
      } = data;
      return status;
    } catch (e) {
      throw convertAxiosError(e);
    }
  }

  cancelAll() {
    for (const canceler of this.cancelers) {
      canceler();
    }
    this.cancelers = [];
  }
}
