import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faUser,
  faUserCog,
  faCog,
  faPen,
  faPlus,
  faMinus,
  faBan,
  faEllipsisV,
  faSpinner,
  faUndo,
  faExclamation,
  faTimes,
  faAngleUp,
  faAngleDown,
  faExclamationCircle,
  faExclamationTriangle,
  faCircle,
  faSync,
  faSort,
  faSyncAlt,
  faCheck,
  faSortUp,
  faSortDown,
  faInfoCircle,
  faCheckSquare,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle as farCircle,
  faClock as farClock,
  faCalendarCheck as farCalendarCheck,
  faFile as farFile,
  faQuestionCircle as farQuestionCircle,
  faSquare as farSquare
} from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationSquare,
  faTimesCircle,
  faArrowDown,
  faArrowUp,
  faTriangle,
  faGrid
} from "@fortawesome/pro-solid-svg-icons";
import {
  faUtensils as farUtensils,
  faExclamationCircle as farExclamationCircle,
  faFileDownload as farFileDownload,
  faExclamationTriangle as farExclamationTriangle
} from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle as fadExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from "@fortawesome/vue-fontawesome";

export function initializeFontAwesome() {
  library.add(
    faAngleLeft,
    faAngleRight,
    faUser,
    faUserCog,
    faCog,
    faPen,
    faPlus,
    faMinus,
    faBan,
    faEllipsisV,
    farCircle,
    faSpinner,
    farClock,
    farUtensils,
    faUndo,
    faExclamation,
    faTimes,
    faAngleUp,
    faAngleDown,
    faExclamationSquare,
    faExclamationCircle,
    faExclamationTriangle,
    fadExclamationTriangle,
    faCircle,
    faSync,
    farCalendarCheck,
    farExclamationCircle,
    farFile,
    faSort,
    farQuestionCircle,
    farFileDownload,
    faSyncAlt,
    faCheck,
    faSortUp,
    faSortDown,
    faTimesCircle,
    faInfoCircle,
    faCheckSquare,
    farSquare,
    faArrowDown,
    faArrowUp,
    farExclamationTriangle,
    faTriangle,
    faDownload,
    faGrid
  );
  Vue.component("font-awesome-icon", FontAwesomeIcon);
  Vue.component("font-awesome-layers", FontAwesomeLayers);
}
