import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const APP_VERSION = (process.env.VUE_APP_VERSION_SHA1 || "0000000").slice(0, 7);

function isDevelopment(): boolean {
  return process.env.VUE_APP_ENV === "development";
}
function isProductionBuild(): boolean {
  return process.env.NODE_ENV === "production";
}

if (isProductionBuild()) {
  Sentry.init({
    Vue: Vue,
    dsn:
      "https://b8f23dd229444d90ba2738e2145b69f0@o422373.ingest.sentry.io/5348080",
    integrations: [
      new Integrations.BrowserTracing({
        idleTimeout: 3000
        // ここで transaction.name の集約しようとすると、 navigation 発生時に遷移元の location で記録されてしまう
        // そのため、 transaction.name の集約は、 transaction を閉じる際に行う
      })
    ],
    sampleRate: 0.1,
    tracesSampleRate: isDevelopment() ? 0 : 0.01,
    release: "zaiko-frontend@" + APP_VERSION,
    environment: process.env.VUE_APP_ENV || "development"
  });
}
