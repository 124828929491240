



































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";

import { RestaurantStatus } from "@/types";
import RestaurantStatusTag from "@/components/RestaurantStatusTag/index.vue";
import { State } from "vuex-class";

@Component({
  components: { RestaurantStatusTag }
})
export default class Header extends Vue {
  @Prop(Number) restaurantId!: number;
  @Prop(String) restaurantName!: string;
  @Prop() restaurantStatus!: RestaurantStatus;
  @Prop(Boolean) hasSiteError!: boolean;
  @Prop(String) operatorName!: string;
  @Prop(Boolean) isIkyuAdmin!: boolean;
  @Prop(Boolean) isPowerUser!: boolean;
  @Prop(Boolean) isReadonly!: boolean;
  @Prop(String) groupId!: string;
  @Prop(Boolean) showTablecheckInfo!: boolean;
  @Prop(Boolean) showTermsAndConditions!: boolean;
  @Prop(Boolean) isAutoPricingEnabled!: boolean;

  @State("uncheckedReservationsCount")
  uncheckedReservationsCount!: number | null;

  isBurgerOpened = false;

  zaikoqLedgerUrl = process.env.VUE_APP_ZAIKOQ_LEDGER_URL;
  zaikoqReservationUrl = process.env.VUE_APP_ZAIKOQ_RESERVATION_URL;

  get hasDuplicatedRestaurantId(): boolean {
    const label = this.restaurantId.toString();
    return label[0] === "6";
  }

  get originalRestaurantIdLabel(): string {
    const label = this.restaurantId.toString();
    if (label[0] === "6") {
      return "1" + label.slice(1);
    }
    return label;
  }

  get reservationsUrl(): string {
    const today = new Date();
    const visitDateFrom =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);

    const params = { visit_date_from: visitDateFrom };
    const query = Object.entries(params)
      .filter(([, v]) => v)
      .map(e => `${e[0]}=${e[1]}`)
      .join("&");
    return `/restaurants/${this.restaurantId}/reservations?${query}`;
  }

  get isShowReservationBadge(): boolean {
    return !!this.uncheckedReservationsCount;
  }

  get uncheckedReservationsCountText(): string {
    if (this.uncheckedReservationsCount) {
      if (this.uncheckedReservationsCount > 9) {
        return "9+";
      } else {
        return this.uncheckedReservationsCount.toString();
      }
    }
    return "";
  }

  burgerClicked() {
    this.isBurgerOpened = !this.isBurgerOpened;
  }
}
