



































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { range, chunk } from "lodash-es";
import {
  parseISO,
  format,
  isAfter,
  isBefore,
  isSunday,
  isSaturday,
  startOfMonth,
  lastDayOfMonth,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  isSameDay,
  isToday
} from "date-fns";
import ToggleButton from "@/components/ToggleButton/index.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PrettyCheckbox = require("pretty-checkbox-vue");
Vue.use(PrettyCheckbox);

@Component({
  components: { ToggleButton },
  methods: {
    isSunday,
    isSaturday,
    isSameDay,
    isToday,
    format,
    parseISO
  }
})
export default class Calendar extends Vue {
  @Prop({ required: true }) month!: Date;
  @Prop() holidays?: Date[];
  @Prop() selectedDates!: { [key: string]: boolean };
  @Prop() underLimitDate?: Date;
  @Prop() upperLimitDate?: Date;

  checkboxClick(refs: HTMLInputElement | Vue) {
    const checkbox = refs;
    if (checkbox instanceof HTMLInputElement) {
      checkbox.click();
    } else if ("$el" in checkbox) {
      const input = checkbox.$el.querySelector("input");
      input && input.click();
    }
  }

  get yearMonth(): string {
    return format(this.calendarDays.filter(c => c)[0], "yyyy年MM月");
  }

  get calendarDays(): Date[] {
    return eachDayOfInterval({
      start: startOfMonth(this.month),
      end: lastDayOfMonth(this.month)
    });
  }

  isUnavailableDate(date: Date): boolean {
    return (
      (!!this.underLimitDate &&
        isBefore(date, startOfDay(this.underLimitDate))) ||
      (!!this.upperLimitDate && isAfter(date, endOfDay(this.upperLimitDate)))
    );
  }

  isHoliday(date: Date): boolean {
    return !!this.holidays && this.holidays.some(d => isSameDay(d, date));
  }

  get eachWeekcalendars(): (Date | null)[][] {
    const firstDay = this.calendarDays[0];
    const firstWeek: (Date | null)[] = range(0, firstDay.getDay()).map(
      () => null
    );
    const lastDay = this.calendarDays[this.calendarDays.length - 1];
    const lastWeek: (Date | null)[] = range(lastDay.getDay(), 6).map(
      () => null
    );

    const allCalendarDays = firstWeek
      .concat(this.calendarDays as (Date | null)[])
      .concat(lastWeek);
    return chunk(allCalendarDays, 7);
  }

  get isAllSelected() {
    return this.calendarDays.every(
      d => this.selectedDates[format(d, "yyyy-MM-dd")]
    );
  }

  isWeekAllSelected(begin: Date, end: Date) {
    return eachDayOfInterval({ start: begin, end }).every(
      d => this.selectedDates[format(d, "yyyy-MM-dd")]
    );
  }

  selectAllDays(selected: boolean) {
    for (const day of this.calendarDays) {
      this.selectedDates[format(day, "yyyy-MM-dd")] = selected;
    }
  }

  selectAllWeekDays(begin: Date, end: Date, selected: boolean) {
    for (const day of eachDayOfInterval({ start: begin, end })) {
      this.selectedDates[format(day, "yyyy-MM-dd")] = selected;
    }
  }
}
